<template>
    <section id="auth" class="main style1 special">
        <div class="container">
			<div class="row gtr-uniform gtr-50 align-left">
                <div class="col-6 col-12-medium">
                    <div class="row gtr-uniform gtr-50">

                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.email"
                                    placeholder="email@domain.com"
                                    type="text"
                                />
                                <div class="text-danger text-sm">{{ state.form.errors.email }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.body.password"
                                    placeholder="Password"
                                    type="password"
                                />
                                <div class="text-danger text-sm">{{ state.form.errors.password }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <input
                                    v-model="state.form.remember"
                                    type="checkbox"
                                    id="remember"
                                    name="remember"
                                >
                                <label for="remember">Remember Me</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <ul class="actions">
                                <li><input
                                        @click="loginDefault"
                                        type="submit"
                                        value="Login"
                                        class="button small ankyra"
                                    />
                                </li>
                            </ul>
                        </div>

                    </div> <!-- class="row gtr-uniform gtr-50" -->
                </div>
			</div>
        </div>
	</section>
</template>

<script>
import {reactive } from 'vue';
import {onMounted} from 'vue';
//import {useStore } from 'vuex';
import {useRouter} from 'vue-router';
import {useAuth  } from '@websanova/vue-auth/src/v3.js';
import useAuthComp from '../../../src/composables/useAuthComp.js';

export default {
    setup() {
        const auth     = useAuth();
        //const store    = useStore();
        const router   = useRouter();
        const authComp = useAuthComp();

        const state = reactive({
            form: {
                body: {
                    email: '',
                    password: '',
                    //remember: false,
                },

                remember: true,
                //fetchUser: state.form.fetchUser,
                fetchUser: true,
                //staySignedIn: state.form.staySignedIn,
                staySignedIn: true,
                errors: {}

            }
        });

        onMounted(() => {
            //console.log(auth.redirect());
        });

        function errors(res) {
            state.form.errors = Object.fromEntries(res.data.errors.map(item => [item.field, item.msg]));
        }


        function loginDefault() {
            auth
            .login({
                data: state.form.body,
                remember: state.form.remember ? '{"name": "Default"}' : null,
                fetchUser: state.form.fetchUser,
                staySignedIn: state.form.staySignedIn,
                redirect: '/'
            })
            .then((res) => {
                console.log(res)
                //auth.user(res.data);
            }, (res) => {
                errors(res.response);
            });
        }

        function loginRedirect() {
            auth
            .login({
                data: state.form.body,
                redirect: {name: 'user-account'},
                remember: state.form.remember ? '{"name": "Redirect"}' : null,
                fetchUser: state.form.fetchUser,
                staySignedIn: state.form.staySignedIn,
            })
            .then(null, (res) => {
                errors(res.response);
            });
        }


        function loginThen() {
            auth
            .login({
                data: state.form.body,
                redirect: null,
                fetchUser: true,
                staySignedIn: true,
            })
            .then((res) => {
                console.log(res)
                if (state.form.remember) {
                    auth.remember(JSON.stringify({
                        name: auth.user().first_name
                    }));
                }

                router.push({name: 'user-account'});
            }, (res) => {
                errors(res.response);
            });
        }

        function showUser() {
            //console.log(auth.fetch().then(data => { console.log(data.data.email) }))
            auth.user(JSON.parse(auth.remember()))
            console.log(auth.user().id)
            console.log(auth.user().email)
            console.log(auth.user().role)
        }

        function loginComp() {
            authComp.login({
                body: state.form.body,
                remember: state.form.remember,
                fetchUser: state.form.fetchUser,
                staySignedIn: state.form.staySignedIn,
            })
            .then(null, (res) => {
                errors(res.response);
            });
        }



        function loginManual() {
            auth.token(null, 'manual', false);

            auth
            .user({
                id: 1,
                first_name: 'Manual',
                email: 'test@manual.com',
                type: 'user',
            });

            if (state.form.remember) {
                auth
                .remember(JSON.stringify({
                    name: auth.user().first_name
                }));
            }
            else {
                auth.unremember();
            }

            router.push({name: 'user-landing'});
        }


        return {
            state,
            loginThen,
            showUser,
            loginComp,
            loginManual,
            loginDefault,
            loginRedirect,
        }
    }
}
</script>
